import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@screentone/core';
import { useProvider } from 'provider/Page/Page.provider';

import './ApplicationBox.styles.css';

const ApplicationBox = ({
  createdAt,
  id,
  label,
  type,
  appKey,
  stackKey,
  showDelete,
  deleteCallback,
}) => {
  const history = useHistory();
  const { setActiveApp, setActiveStack } = useProvider();

  const clickApplication = () => {
    if (type === 's3') {
      setActiveApp({ label, createdAt, id, appKey });
      history.push(`/app/${appKey}/stacks`);
    } else {
      setActiveStack({ label, createdAt, id, stackKey });
      history.push(`/app/${appKey}/stacks/${stackKey}/components`);
    }
  };

  return (
    <Box margin={{ bottom: 'md' }}>
      <Box.Title>
        <div className='st_app_title_box'>
          <Button tertiary className='st_trunacted_text' onClick={clickApplication}>
            {label}
          </Button>
          {showDelete && (
            <Button
              icon='trash'
              color='lava'
              tertiary
              onClick={() => deleteCallback(appKey)}
            />
          )}
        </div>
      </Box.Title>
      <Box.Content className='st_app_box_content'>
        <div className='st_app_box_date'>
          Created: {new Date(createdAt).toLocaleString()}
        </div>
      </Box.Content>
    </Box>
  );
};

ApplicationBox.propTypes = {
  label: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  appKey: PropTypes.string,
  stackKey: PropTypes.string,
  showDelete: PropTypes.bool,
  deleteCallback: PropTypes.func,
};

ApplicationBox.defaultProps = {
  appKey: '',
  stackKey: '',
  showDelete: false,
  deleteCallback: () => {},
};

export default ApplicationBox;
