import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  PageWrapper,
  Modal,
  Input,
  Button,
  Box,
  Typography,
  Grid,
} from '@screentone/core';
import AppCreation from 'components/AppCreation';
import Loading from 'components/Loading';
import ApplicationBox from 'components/Box';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import './Application.styles.css';

const WrapperContext = React.createContext(null);

function useS3DeploymentWrapper() {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("Can't use without Wrapper Provider!");
  }
  return context;
}

const S3Deployment = () => {
  const { oktaAuth } = useAuth();
  const [apps, setApps] = useState(null);
  const [error, setError] = useState(null);
  const [jwt, setJWT] = useState(null);

  const [canDelete, setCanDelete] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const getUser = useCallback(async () => {
    const user = await oktaAuth.getUser();
    setCanDelete(user.app_scope.includes('app:delete'));
  }, [oktaAuth]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    try {
      const getApplications = async () => {
        const accessToken = await oktaAuth.getAccessToken();
        const api = await API({ accessToken });
        api.app.get
          .all()
          .then(applications => {
            setApps(applications.apps);
            setJWT(accessToken);
          })
          .catch(e => {
            setError(e);
          });
      };
      getApplications();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error:', err);
      setError(err);
    }
  }, [oktaAuth]);

  const confirmDeletion = deletedAppKey => setDeletePrompt(deletedAppKey);
  const closePrompt = () => {
    setDeleteConfirmation('');
    setDeletePrompt(null);
  };

  const deleteApplication = async () => {
    if (jwt) {
      const api = await API({ accessToken: jwt });
      const res = await api.apps.delete(deletePrompt);
      if (res.rowCount === 1) {
        setApps(apps.filter(a => a.key !== deletePrompt));
      }
      closePrompt();
    } else {
      closePrompt();
    }
  };

  const value = { apps, setApps };
  console.log('apps: ', apps);

  return (
    <WrapperContext.Provider value={value}>
      <PageWrapper>
        {deletePrompt && (
          <Modal onDismiss={closePrompt}>
            <Box>
              <Box.Title>
                <div>{`Are you sure you want to delete "${deletePrompt}"?`}</div>
                <p>This cannot be undone.</p>
              </Box.Title>
              <Box.Content>
                <Input
                  placeholder='Type the complete app name here to confirm deletion'
                  onChange={e => setDeleteConfirmation(e.target.value)}
                  value={deleteConfirmation}
                />
                <Button
                  margin={{ top: 'mlg', right: 'sm' }}
                  onClick={deleteApplication}
                  disabled={deleteConfirmation !== deletePrompt}
                  primary
                >
                  Delete
                </Button>
                <Button onClick={closePrompt} secondary>
                  Cancel
                </Button>
              </Box.Content>
            </Box>
          </Modal>
        )}
        <AppCreation />
        <Typography componentEl='h1' size='xxl'>
          Applications
        </Typography>
        {!error && !apps && <Loading />}
        {error && <div>API Error, can not load the applications</div>}
        {apps && apps.length === 0 && <div>There are no applications so far</div>}
        {apps && apps.length > 0 && (
          <Grid>
            <Grid.Row>
              {apps.map(application => {
                return (
                  <Grid.Col xs={4} key={`app-box-${application.id}`}>
                    <ApplicationBox
                      {...application}
                      appKey={application.key}
                      type='s3'
                      showDelete={canDelete && application.controlled}
                      deleteCallback={confirmDeletion}
                    />
                  </Grid.Col>
                );
              })}
            </Grid.Row>
          </Grid>
        )}
      </PageWrapper>
    </WrapperContext.Provider>
  );
};

export { useS3DeploymentWrapper };
export default S3Deployment;
