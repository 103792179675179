import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PageWrapper, Breadcrumbs, Grid, Typography } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import Loading from 'components/Loading';
import ApplicationBox from 'components/Box';
import AppCreation from 'components/AppCreation';

import API from 'api/api';
import './Stack.styles.css';

const WrapperContext = React.createContext(null);

function useStackWrapper() {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("Can't use without Wrapper Provider!");
  }
  return context;
}

const Stack = () => {
  const { appKey } = useParams();
  const { oktaAuth } = useAuth();

  const [stacks, setStacks] = useState(null);

  useEffect(() => {
    const getStackByApplication = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const api = await API({ accessToken });
      const stacksResult = await api.app.stacks(appKey);
      const result = (stacksResult && stacksResult.data && stacksResult.data[0]) || null;
      setStacks((result && result.stacks) || null);
    };

    try {
      getStackByApplication();
    } catch (error) {
      console.log('error getting stacks: ', error);
    }
  }, [appKey, oktaAuth]);

  const value = { stacks, setStacks };

  return (
    <WrapperContext.Provider value={value}>
      <PageWrapper>
        <Breadcrumbs margin={{ top: 'md' }}>
          <Breadcrumbs.Item componentEl='a' href='/apps'>
            Applications
          </Breadcrumbs.Item>
          <Breadcrumbs.Item current>{appKey}</Breadcrumbs.Item>
        </Breadcrumbs>
        <AppCreation type='stack' />
        <Typography componentEl='h1' size='xxl'>
          Stacks
        </Typography>
        {!stacks && <Loading />}
        {stacks && stacks.length === 0 && <div>There are no stacks so far</div>}
        {stacks && stacks.length > 0 && (
          <Grid>
            <Grid.Row>
              {stacks.map(application => {
                return (
                  <Grid.Col xs={4} key={`stack-box-${application.id}`}>
                    <ApplicationBox
                      key={`stack-box${application.id}`}
                      {...application}
                      stackKey={application.key}
                      appKey={appKey}
                      type='stack'
                    />
                  </Grid.Col>
                );
              })}
            </Grid.Row>
          </Grid>
        )}
      </PageWrapper>
    </WrapperContext.Provider>
  );
};

export { useStackWrapper };
export default Stack;
