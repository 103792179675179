import React, { useEffect, useState } from 'react';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import DeployCreation from 'components/DeployCreation';
import DeployRow from 'components/Tabs/S3Deploy/DeployRow.component';
import { useProvider } from 'provider/Page/Page.provider';
import { useDeploymentWrapper } from 'pages/Deployment/Deployment.page';

import './S3Deploy.styles.css';

const S3DeployTab = () => {
  const { oktaAuth } = useAuth();
  const { deployment: initDeployment } = useDeploymentWrapper();
  const [deploys, setDeploys] = useState([]);

  const { activeModule } = useProvider();

  useEffect(() => {
    setDeploys(oldArray => [...oldArray, ...initDeployment]);
  }, [initDeployment]);

  useEffect(() => {
    const getStackByApplication = async () => {
      if (activeModule && activeModule.id) {
        const accessToken = await oktaAuth.getAccessToken();
        const am = await API({ accessToken });
        const result = await am.component.deploy({ componentId: activeModule.id });
        setDeploys(oldArray => [...oldArray, ...result]);
      }
    };

    try {
      getStackByApplication();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error getting stacks: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModule]);

  return (
    <React.Fragment>
      <DeployCreation setDeploys={setDeploys} />
      <table className='table'>
        <tbody>
          {deploys.length === 0 ? (
            <tr>
              <th colSpan='4'>No Deployments Found</th>
            </tr>
          ) : (
            deploys.map(deploy => {
              return <DeployRow key={deploy.SK} {...deploy} />;
            })
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default S3DeployTab;
