import React from 'react';
import PropTypes from 'prop-types';
import { FabButton, Modal, useModalState, useModalPortal } from '@screentone/core';

import Loading from 'components/Loading';
import AppCreationForm from 'components/AppCreationForm';
import StackCreationForm from 'components/StackCreationForm';
import ComponentCreationForm from 'components/ComponentCreationForm';

function AppCreation({ type }) {
  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();

  return (
    <React.Fragment>
      <FabButton icon='plus' onClick={() => openModal(true)} data-testid='fab-button' />
      {open && (
        <Modal
          onDismiss={closeModal}
          renderNode={renderNode}
          data-testid='mdl-create-app'
        >
          <React.Suspense fallback={<Loading />}>
            {type === 'app' ? <AppCreationForm closeModal={closeModal} /> : null}
            {type === 'stack' ? <StackCreationForm closeModal={closeModal} /> : null}
            {type === 'component' ? (
              <ComponentCreationForm closeModal={closeModal} />
            ) : null}
          </React.Suspense>
        </Modal>
      )}
    </React.Fragment>
  );
}

AppCreation.propTypes = {
  type: PropTypes.string,
};

AppCreation.defaultProps = {
  type: 'app',
};

export default AppCreation;
