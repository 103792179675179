import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button } from '@screentone/core';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useProvider } from 'provider/Page/Page.provider';

import './ApplicationBox.styles.css';

const ComponentBox = ({ description, name, id, createdAt, componentKey }) => {
  const history = useHistory();
  const { appKey, stackKey } = useParams();
  const { oktaAuth } = useAuth();
  const [configurationUrl, setConfigurationUrl] = useState(null);
  const { setActiveModule } = useProvider();

  useEffect(() => {
    const getConfigurationData = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const response = await am.component.config(id);
      setConfigurationUrl(response[0].gitUrl);
    };

    try {
      getConfigurationData();
    } catch (error) {
      console.log('error getting app data: ', error);
    }
  }, [oktaAuth, id]);

  const clickApplication = () => {
    setActiveModule({ id, name, description, gitUrl: configurationUrl, componentKey });
    history.push(`/app/${appKey}/stacks/${stackKey}/components/${componentKey}/builds`);
  };

  return (
    <div className='st_column_application'>
      <Box margin={{ all: 'md' }} fullWidth>
        <Box.Title>
          <Button tertiary onClick={clickApplication}>
            {`S3 Module - ${name}`}
          </Button>
        </Box.Title>
        <Box.Content className='st_app_box_content'>
          <div>{description}</div>
          <div>{configurationUrl}</div>
          <div className='st_app_box_date'>
            Created: {new Date(createdAt).toLocaleString()}
          </div>
        </Box.Content>
      </Box>
    </div>
  );
};

ComponentBox.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  componentKey: PropTypes.string.isRequired,
};

export default ComponentBox;
