import React, { useState, useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';

import 'swagger-ui-react/swagger-ui.css';

const Swagger = () => {
  // TODO: move this to an object that is passed down as a prop from parent component or context
  const [spec, setSpec] = useState(null);
  useEffect(() => {
    (async () => {
      let data = await fetch('http://int.dev.oktaapi.oregon.dj01.onservo.com/api/config');
      if (data && data.ok) data = await data.json();
      if (data && data.swagger) setSpec(data.swagger);
    })();
  }, []);

  return (
    <div className='swagger_wrapper'>
      {spec && <SwaggerUI docExpansion='list' spec={spec} />}
    </div>
  );
};

export default Swagger;
