import clone from 'lodash/clone';
import config from 'config/app.json';

const mergeStackReducer = (oldState, newState) => {
  console.log('mergeStackReducer: ', oldState, newState);
  const copyNewState = clone(newState);
  // if (newState.stackName) {
  //   const stackKeyValue = newState.stackName.value
  //     .trim()
  //     .toLowerCase()
  //     .replace(/[^A-Z0-9]+/gi, '-');

  //   copyNewState.stackKey = {
  //     value: stackKeyValue,
  //     dirty: newState.stackName.dirty,
  //     validating: false,
  //     error: null,
  //   };
  // }

  if (newState.clientUri) {
    const redirectUrisValue = newState.clientUri.value.trim().toLowerCase();

    copyNewState.redirectUris = {
      value: [`${redirectUrisValue}/login/callback`],
      dirty: newState.clientUri.dirty,
      validating: false,
      error: null,
    };
    copyNewState.logoutUris = {
      value: [`${redirectUrisValue}/logout`],
      dirty: newState.clientUri.dirty,
      validating: false,
      error: null,
    };
  }

  console.log(`return`, { ...oldState, ...copyNewState });
  return { ...oldState, ...copyNewState };
};

const mergeReducer = (oldState, newState) => {
  return { ...oldState, ...newState };
};

function identity(value) {
  return value;
}

async function asyncIdentity(value) {
  return identity(value);
}

const formatPayload = payload => {
  const payloadFormatted = {};
  Object.keys(payload).forEach(item => {
    if (item === 'oktaDomain') {
      payloadFormatted.preview = payload[item].value === 'newscorp.oktapreview.com';
    } else {
      payloadFormatted[item] = payload[item].value;
    }
  });

  return payloadFormatted;
};

/**
 * Returns an async function that runs a pipeline of unary functions through completion
 * Useful for composing async validations into a single function definition
 */
function asyncPipe(...functions) {
  return async function runPipeline(initialValue) {
    return functions.reduce(async function reduceCb(returnedPromise, currentFunction) {
      const value = await returnedPromise;
      return currentFunction(value);
    }, Promise.resolve(initialValue));
  };
}

/**
 * gets the environment from browser hostname
 * this is needed because client is served from static bucket
 */
function getEnv() {
  const envRegex = /^(.*?).?apps.djnews.tools$/;
  const match = window.location.host.match(envRegex);
  return (match && (match[1] || 'prod')) || 'local';
}

function getConfig() {
  return config[getEnv()];
}

export {
  mergeReducer,
  mergeStackReducer,
  asyncIdentity,
  formatPayload,
  asyncPipe,
  getEnv,
  getConfig,
};
