import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PageWrapper, Loader, Typography } from '@screentone/core';

function Loading({ appearIn, withTopMargin, withText }) {
  const [loaded, setLoaded] = useState(appearIn === 0);

  useEffect(() => {
    if (appearIn === 0) return;
    const timeoutId = setTimeout(() => setLoaded(true), appearIn);
    return () => clearTimeout(timeoutId);
  }, [appearIn]);

  if (!loaded) {
    return null;
  }

  return (
    <PageWrapper>
      <Typography align='center' style={{ marginTop: withTopMargin ? '20%' : null }}>
        <Loader />
      </Typography>
      {withText && (
        <Typography color='asphalt' align='center' margin={{ vertical: 'md' }}>
          Loading...
        </Typography>
      )}
    </PageWrapper>
  );
}

Loading.propTypes = {
  appearIn: PropTypes.number,
  withTopMargin: PropTypes.bool,
  withText: PropTypes.bool,
};

Loading.defaultProps = {
  appearIn: 300,
  withTopMargin: true,
  withText: true,
};

export default Loading;
