import NewsroomFetchAPI from '@newsroom-dev/api-fetch';
import API_ENDPOINTS from 'config/endpoints';
import { getConfig } from 'utils/fns';

const client = ({ accessToken }) => {
  const CONFIG = getConfig();
  const url = CONFIG.api.url || window.location.origin;
  const apiOptions = {
    catchHTTPErrors: true,
  };

  const apiFetch = new NewsroomFetchAPI(url, API_ENDPOINTS, apiOptions);

  apiFetch.use((name, definition, fetchInit) => {
    fetchInit.headers.set('Accept', definition.accept || 'application/json');
    fetchInit.headers.set('Content-Type', definition.contentType || 'application/json');
    if (accessToken && definition.authenticated) {
      fetchInit.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return fetchInit;
  });

  return apiFetch;
};

export default client;
