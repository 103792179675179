import React from 'react';
import PropTypes from 'prop-types';
import { FabButton, Modal, useModalState, useModalPortal } from '@screentone/core';

import Loading from 'components/Loading';
import BuildCreationForm from 'components/BuildCreationForm';

function AppCreation({ setBuilds }) {
  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();

  return (
    <React.Fragment>
      <FabButton
        icon='plus'
        onClick={() => openModal(true)}
        data-testid='fab-button-build'
      />
      {open && (
        <Modal
          onDismiss={closeModal}
          renderNode={renderNode}
          data-testid='mdl-create-app'
        >
          <React.Suspense fallback={<Loading />}>
            <BuildCreationForm closeModal={closeModal} setBuilds={setBuilds} />
          </React.Suspense>
        </Modal>
      )}
    </React.Fragment>
  );
}

AppCreation.propTypes = {
  setBuilds: PropTypes.func.isRequired,
};

export default AppCreation;
