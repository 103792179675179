import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageWrapper, Tabs, Button } from '@screentone/core';
import { useProvider } from 'provider/Page/Page.provider';
import BuildTab from 'components/Tabs/S3Build';
import DeployTab from 'components/Tabs/S3Deploy';
import { useAuth } from '@screentone/addon-auth-wrapper';
import Loading from 'components/Loading';
import API from 'api/api';
import './Deployment.styles.css';

const hiddenStyle = { display: 'none' };

const WrapperContext = React.createContext(null);

function useDeploymentWrapper() {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("Can't use without Wrapper Provider!");
  }
  return context;
}

const S3Dashboard = () => {
  const history = useHistory();
  const { appKey, stackKey, componentKey } = useParams();
  const [activeTab, setActiveTab] = useState('build');
  const [errorPage, setErrorPage] = useState(false);
  const [deployment, setDeployment] = useState([]);
  const { oktaAuth } = useAuth();
  const {
    activeApp,
    setActiveApp,
    activeStack,
    setActiveStack,
    activeModule,
    setActiveModule,
  } = useProvider();

  useEffect(() => {
    const getApplicationData = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      let stData;
      let module;
      if (activeModule === null) {
        const am = await API({ accessToken });
        const componentData = await am.componentByKey(appKey, stackKey, componentKey);
        const component = componentData.component[0];
        const configData = await am.component.config(component.id);

        module = {
          ...componentData.component[0],
          gitUrl: configData[0].gitUrl,
        };
        setActiveModule(module);
      }
      if (activeStack === null) {
        const am = await API({ accessToken });
        const stackData = await am.stackByKey(stackKey);
        // eslint-disable-next-line prefer-destructuring
        stData = stackData.stack[0];
        setActiveStack(stData);
      }
      if (activeApp === null) {
        const am = await API({ accessToken });
        const appData = await am.appByKey(appKey);
        setActiveApp(appData.app[0]);
      }
    };

    try {
      getApplicationData();
    } catch (error) {
      setErrorPage(true);
      // eslint-disable-next-line no-console
      console.log('error getting app data: ', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentKey]);

  const backToComponents = () => {
    setActiveModule(null);
    history.push(`/app/${appKey}/stacks/${stackKey}/components`);
  };

  const title = activeStack ? activeStack.label : 'Undefined';
  const url = activeModule ? activeModule.gitUrl : 'Undefined';
  const buildStyle = activeTab === 'deploy' ? hiddenStyle : {};
  const deployStyle = activeTab === 'build' ? hiddenStyle : {};

  const value = { deployment, setDeployment };

  if (errorPage) {
    history.replace('/error/500');
  }

  if (!activeApp || !activeModule || !activeStack) {
    return <Loading />;
  }

  return (
    <WrapperContext.Provider value={value}>
      <PageWrapper>
        <div className='st_headerbar_custom'>
          <Button tertiary icon='caret-left' onClick={backToComponents}>
            Back
          </Button>
          <br />
          <span style={{ marginRight: '20px' }}>{title}</span>
          <span>{url}</span>
        </div>
        <Tabs value={activeTab}>
          <Tabs.Item onClick={() => setActiveTab('build')} value='build'>
            Builds
          </Tabs.Item>
          <Tabs.Item onClick={() => setActiveTab('deploy')} value='deploy'>
            Deployments
          </Tabs.Item>
        </Tabs>

        <div style={buildStyle}>
          <BuildTab setDeployment={setDeployment} />
        </div>
        <div style={deployStyle}>
          <DeployTab />
        </div>
      </PageWrapper>
    </WrapperContext.Provider>
  );
};

export { useDeploymentWrapper };
export default S3Dashboard;
