import React, { useContext, useState } from 'react';

const WrapperContext = React.createContext(null);

function useProvider() {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("Can't use without Wrapper Provider!");
  }
  return context;
}

function PageProvider({ ...props }) {
  const [activeApp, setActiveApp] = useState(null);
  const [activeStack, setActiveStack] = useState(null);
  const [activeModule, setActiveModule] = useState(null);

  const value = {
    activeApp,
    setActiveApp,
    activeStack,
    setActiveStack,
    activeModule,
    setActiveModule,
  };

  return <WrapperContext.Provider value={value} {...props} />;
}

export { useProvider };
export default PageProvider;
