import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'utils/hooks/useInterval';
import { Loader } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import API from 'api/api';

const DeployRow = ({ status, description, userEmail, createdAt, SK }) => {
  const { oktaAuth } = useAuth();
  const [delay, setDelay] = useState(5000);
  const [statusDeploy, setStatusDeploy] = useState(status);

  const customClass = statusDeploy.toLowerCase();
  const desc = description.length > 40 ? `${description.substr(0, 50)}...` : description;

  const deployId = SK.replace('deploy_', '');

  const callback = async () => {
    try {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const buildData = await am.deploy.byId({ deployId });
      const data = buildData[0];
      if (data.status === 'COMPLETED') {
        setStatusDeploy('COMPLETED');
        setDelay(null);
      }
    } catch (error) {
      setStatusDeploy('FAIL');
      setDelay(null);
    }
  };

  useInterval(callback, delay);

  return (
    <React.Fragment>
      <tr key={`row-${SK}`}>
        <th className={customClass}>{statusDeploy}</th>
        <th>{userEmail}</th>
        <th>{desc}</th>
        <th>{new Date(createdAt).toLocaleString()}</th>
        {statusDeploy === 'COMPLETED' ? null : (
          <th>
            <Loader size='md' />
          </th>
        )}
      </tr>
    </React.Fragment>
  );
};

DeployRow.propTypes = {
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  SK: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
};

export default DeployRow;
