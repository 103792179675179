import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  PageWrapper,
  // Breadcrumbs,
  Box,
  Button,
  Input,
  Grid,
  Icon,
  Token,
  Dropdown,
  Tabs,
  Table,
  Typography,
  HeaderBar,
  Checkbox,
  FormLabel,
} from '@screentone/core';
import { useAuth, LoadingPage } from '@screentone/addon-auth-wrapper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import Loading from 'components/Loading';
import ComponentBox from 'components/Box/ComponentBox.component';
import UserAppGroup from 'components/UserAppGroup';
import API from 'api/api';

import './Module.styles.css';

const WrapperContext = React.createContext(null);

function useModuleWrapper() {
  const context = useContext(WrapperContext);
  if (!context) {
    throw new Error("Can't use without Wrapper Provider!");
  }
  return context;
}
// eslint-disable-next-line react/prop-types
const CopyToken = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef(null); // REF TO KEEP TRACK OF THE TIMEOUT
  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      // SET A TIMEOUT
      timeoutRef.current = null;
      setCopied(false);
    }, 500); // AFTER 500ms
  }, [copied]);
  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <Token
        title='Click to copy'
        className='st-token-link'
        componentEl='button'
        color={copied ? 'emerald' : 'gray'}
        icon={copied ? 'check' : 'copy'}
      >
        {text}
      </Token>
    </CopyToClipboard>
  );
};

const Module = () => {
  const { appKey, stackKey } = useParams();
  const { oktaAuth } = useAuth();

  const [activeTab, updateActiveTab] = useState('users');
  const [modules, setModules] = useState(null);
  const [userSearch, setUserSearch] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState(false);
  const [userGroupData, setUserGroupData] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [appData, setAppData] = useState({});
  // const [showApps, setShowApps] = useState(false);
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef(null); // REF TO KEEP TRACK OF THE TIMEOUT

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      // SET A TIMEOUT
      timeoutRef.current = null;
      setCopied(false);
    }, 500); // AFTER 500ms
  }, [copied]);

  useEffect(() => {
    const getStackByApplication = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const api = await API({ accessToken });
      const componentsData = await api.stack.components(appKey, stackKey);
      api
        .stackByKey(appKey, stackKey)
        .then(response => setAppData(response))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          setAppData({ error: true });
        });
      setModules(componentsData.components);
    };

    try {
      if (!modules) {
        getStackByApplication();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error getting stacks: ', error);
    }
  }, [appKey, oktaAuth, modules, stackKey]);

  const updateSearch = e => {
    setUserSearch(e.currentTarget.value);
  };

  const getGroups = useCallback(async () => {
    setUserLoading(true);
    if (userGroupData) {
      setAssignedUser(null);
      setUserGroupData(null);
    }
    const accessToken = await oktaAuth.getAccessToken();
    const api = API({ accessToken }).use((_n, _d, fetchInit) => {
      fetchInit.headers.set('preview', appData.oktaPreview);
      return fetchInit;
    });
    api
      .listUserAppAssignments(appKey, stackKey, userSearch)
      .then(groupData => {
        console.log('groupData: ', groupData);
        if (userError) setUserError(false);
        setUserLoading(false);
        setUserGroupData(groupData.groups);
        if (groupData.user) {
          setAssignedUser(groupData.user); // assignedUser
        }
      })
      .catch(groupDataError => {
        // eslint-disable-next-line no-console
        console.error(groupDataError);
        setUserError(true);
        setUserLoading(false);
        setUserGroupData(null);
      });
  });

  const searchUser = useCallback(
    e => {
      if (e.key === 'Enter') {
        getGroups();
      } else if (e.key === 'Escape') {
        setUserSearch('');
        setUserError(false);
      }
    },
    [getGroups]
  );

  const value = { modules, setModules };

  const issuer = `https://newscorp.okta${
    appData.oktaPreview ? 'preview' : ''
  }.com/oauth2/${appData.oktaAuthServerId || 'default'}`;

  const authWrapperSettings = `const clientId = '${appData.oktaAppId}';
const issuer = '${issuer}';
const redirectUri = '${
    get(appData, 'oktaData.app.settings.oauthClient.redirect_uris', [])[0]
  }';

const appConfig = {
  key: '${appKey}-${stackKey}',
  name: '${appData.appLabel}',
 ${
   !['prod' || 'production'].includes(stackKey)
     ? ` env: '${stackKey}',
`
     : ''
 }}

const authConfig = { clientId, issuer, redirectUri };`;

  const validateOktaTokenConfig = `const audience = ${
    get(appData, 'oktaData.authServer.audiences', [])[0]
  };
const clientId = '${appData.oktaAppId}';
const issuer = '${issuer}';

const oktaConfig = {
  assertClaims: {
    aud: audience,
    cid: clientId
  },
  clientId,
  issuer,
};`;
  console.log('appData: ', appData);
  console.log('userGroupData: ', userGroupData);
  return (
    <WrapperContext.Provider value={value}>
      <PageWrapper>
        {!(modules && appData.oktaData) && <LoadingPage />}
        {modules && appData.oktaData && (
          <React.Fragment>
            <Typography variant='header2' margin={{ vertical: 'md' }}>
              {appData.appLabel} <Token color='blurple'>{appData.label}</Token>
            </Typography>
            <Tabs onChange={updateActiveTab} value={activeTab}>
              <Tabs.Item value='auth'>Okta Config</Tabs.Item>
              <Tabs.Item value='users'>User Management</Tabs.Item>
              {modules && modules.length > 0 && (
                <Tabs.Item value='components'>Components</Tabs.Item>
              )}
            </Tabs>
            {/* TODO: eventually this should be more than just a search field. it should be a paginated list */}
            {activeTab === 'auth' && (
              <React.Fragment>
                <Box margin={{ vertical: 'lg' }}>
                  <Box.Title>Okta Settings</Box.Title>
                  <Box.Content>
                    {appData.oktaData && (
                      <div className='okta-setting'>
                        <Typography variant='header3'>General</Typography>

                        <Table margin={{ bottom: 'md' }} fullWidth border='horizontal'>
                          <Table.Body>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Okta Account:</Table.Cell>
                              <Table.Cell>
                                <CopyToken
                                  text={`https://newscorp.okta${
                                    appData.oktaPreview ? 'preview' : ''
                                  }.com/`}
                                />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Okta Application name: </Table.Cell>
                              <Table.Cell>
                                <CopyToken text={get(appData, 'oktaData.app.label')} />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        <Typography variant='header3'>Login</Typography>

                        <Table margin={{ bottom: 'md' }} fullWidth border='horizontal'>
                          <Table.Body>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Client ID:</Table.Cell>
                              <Table.Cell>
                                <CopyToken text={appData.oktaAppId} />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>
                                Initiate Login URI: <br />
                                (Okta tile link)
                              </Table.Cell>
                              <Table.Cell>
                                <CopyToken
                                  text={get(
                                    appData,
                                    'oktaData.app.settings.oauthClient.initiate_login_uri'
                                  )}
                                />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Login Redirect URIs:</Table.Cell>
                              <Table.Cell>
                                {get(
                                  appData,
                                  'oktaData.app.settings.oauthClient.redirect_uris',
                                  []
                                ).map(uri => (
                                  <React.Fragment key={uri}>
                                    <CopyToken text={uri} />
                                    <br />
                                  </React.Fragment>
                                ))}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Logout Redirect URIs:</Table.Cell>
                              <Table.Cell>
                                {get(
                                  appData,
                                  'oktaData.app.settings.oauthClient.post_logout_redirect_uris',
                                  []
                                ).map(uri => (
                                  <React.Fragment key={uri}>
                                    <CopyToken text={uri} />
                                    <br />
                                  </React.Fragment>
                                ))}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        <Typography variant='header3'>Auth Server</Typography>
                        <Table margin={{ bottom: 'md' }} fullWidth border='horizontal'>
                          <Table.Body>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Issuer:</Table.Cell>
                              <Table.Cell>
                                <CopyToken text={issuer} />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row hoverable>
                              <Table.Cell isLabel>Audience:</Table.Cell>
                              <Table.Cell>
                                {get(appData, 'oktaData.authServer.audiences', []).map(
                                  uri => (
                                    <React.Fragment key={uri}>
                                      <CopyToken text={uri} />
                                      <br />
                                    </React.Fragment>
                                  )
                                )}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        <Typography variant='header3'>Profile</Typography>
                        <Table margin={{ bottom: 'md' }} fullWidth border='horizontal'>
                          <Table.Body>
                            {Object.keys(
                              get(
                                appData,
                                'oktaData.appSchema.definitions.custom.properties',
                                {}
                              )
                            ).map(prop => {
                              const props = get(
                                appData,
                                'oktaData.appSchema.definitions.custom.properties'
                              );
                              const item = props[prop];
                              return (
                                <Table.Row hoverable key={prop}>
                                  <Table.Cell isLabel>
                                    {item.title}
                                    <br />
                                    <CopyToken text={prop} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {item.description && (
                                      <Typography variant='bodytext'>
                                        {item.description}
                                      </Typography>
                                    )}
                                    {item.type === 'array' &&
                                      item.items.type === 'string' &&
                                      item.items.oneOf && (
                                        <React.Fragment>
                                          <p>
                                            <Typography variant='note'>
                                              Type: <code>String Array</code>
                                              {item.scope === 'SELF' &&
                                                ' Scoped to user.'}
                                              {item.union === 'ENABLE' &&
                                                ' Values combined across groups.'}
                                              <br />
                                              One of:
                                            </Typography>
                                          </p>
                                          {item.items.oneOf.map(i => (
                                            <div key={i.const}>
                                              <Token color='blurple'>{i.title}</Token>{' '}
                                              <CopyToken text={i.const} />
                                            </div>
                                          ))}
                                        </React.Fragment>
                                      )}
                                    {item.type === 'array' &&
                                      item.items.type === 'string' &&
                                      !item.items.oneOf && (
                                        <p>
                                          <Typography variant='note'>
                                            Type: <code>String Array</code>
                                            {item.scope === 'SELF' && ' Scoped to user.'}
                                            {item.union === 'ENABLE' &&
                                              ' Values combined across groups.'}
                                          </Typography>
                                        </p>
                                      )}
                                    {item.type === 'boolean' && (
                                      <p>
                                        <Typography variant='note'>
                                          Type: <code>String Array</code>
                                          {item.scope === 'SELF' && ' Scoped to user.'}
                                          {item.union === 'ENABLE' &&
                                            ' Values combined across groups.'}
                                        </Typography>
                                      </p>
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      </div>
                    )}
                  </Box.Content>
                </Box>
                {appData.oktaData && (
                  <React.Fragment>
                    <Box margin={{ vertical: 'lg' }}>
                      <Box.Title>
                        <div style={{ display: 'flex' }}>
                          Auth Wrapper Client Config{' '}
                          <div
                            style={{
                              marginLeft: 'auto',
                              height: 'var(--st-spacer-md)',
                              marginTop: '-2px',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {copied && (
                              <Token
                                style={{ verticalAlign: 'top', marginTop: '2px' }}
                                color='emerald'
                              >
                                Copied
                              </Token>
                            )}
                            <CopyToClipboard
                              text={authWrapperSettings}
                              onCopy={() => setCopied(true)}
                            >
                              <Button tertiary icon='copy' />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Box.Title>
                      <Box.Content>
                        <pre>{authWrapperSettings}</pre>
                      </Box.Content>
                    </Box>
                    <Box margin={{ vertical: 'lg' }}>
                      <Box.Title>
                        <div style={{ display: 'flex' }}>
                          Validate Okta Token Config - Server{' '}
                          <div
                            style={{
                              marginLeft: 'auto',
                              height: 'var(--st-spacer-md)',
                              marginTop: '-2px',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {copied && (
                              <Token
                                style={{ verticalAlign: 'top', marginTop: '2px' }}
                                color='emerald'
                              >
                                Copied
                              </Token>
                            )}
                            <CopyToClipboard
                              text={validateOktaTokenConfig}
                              onCopy={() => setCopied(true)}
                            >
                              <Button tertiary icon='copy' />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Box.Title>
                      <Box.Content>
                        <pre>{validateOktaTokenConfig}</pre>
                      </Box.Content>
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {activeTab === 'users' && (
              <React.Fragment>
                <Box margin={{ vertical: 'lg' }}>
                  <Box.Title>User Management</Box.Title>
                  <Box.Content>
                    <Input
                      icon='search'
                      placeholder='Search for a user by email'
                      error={userError}
                      margin={{ bottom: 'md' }}
                      value={userSearch}
                      onChange={updateSearch}
                      onKeyUp={searchUser}
                    />
                    {userLoading && (
                      <Loading withText={false} withTopMargin={false} appearIn={0} />
                    )}

                    {userGroupData && (
                      <Grid.Row>
                        <Grid.Col xs={12}>
                          {assignedUser && (
                            <React.Fragment>
                              <Typography>
                                <Token color='blurple'>{assignedUser.profile.name}</Token>{' '}
                                is <Token color='emerald'>Assigned</Token> to{' '}
                                <Token>{appData.appLabel}</Token>{' '}
                                <Token color='blurple'>{appData.label}</Token>
                              </Typography>
                              {(assignedUser.profile.app_properties ||
                                assignedUser.profile.appScope ||
                                assignedUser.profile.app_scope ||
                                assignedUser.profile.app_roles) && (
                                <Typography>
                                  {assignedUser.profile.given_name} has access to:
                                </Typography>
                              )}
                              <div className='productListWrapper'>
                                {uniq(assignedUser.profile.app_properties)
                                  .sort()
                                  .map(item => (
                                    <HeaderBar.Product
                                      className='productListItem'
                                      key={item}
                                      title={item}
                                      type={item}
                                    />
                                  ))}
                              </div>
                              <div>
                                {uniq(
                                  assignedUser.profile.appScope ||
                                    assignedUser.profile.app_scope ||
                                    assignedUser.profile.app_roles
                                )
                                  .sort()
                                  .map(scope => (
                                    <Token
                                      key={scope}
                                      color='gray'
                                      margin={{ horizontal: 'xs' }}
                                    >
                                      {scope}
                                    </Token>
                                  ))}
                              </div>
                              <Typography margin={{ top: 'sm' }}>
                                {assignedUser.profile.given_name} is assigned as a{' '}
                                <Token>
                                  {assignedUser.scope === 'USER' ? 'Individual' : 'Group'}{' '}
                                  user
                                </Token>
                                {assignedUser.scope === 'USER'
                                  ? ' and the '
                                  : ' via the '}
                                {userGroupData.current.map(group => (
                                  <Token margin={{ horizontal: 'xs' }}>
                                    {group.name}
                                  </Token>
                                ))}{' '}
                                group{userGroupData.current.length > 1 ? 's' : ''}.
                              </Typography>
                            </React.Fragment>
                          )}
                          {!assignedUser && userGroupData.current.length === 0 && (
                            <React.Fragment>
                              <Typography>
                                <Token>{userGroupData.oktaProfile.displayName}</Token> is{' '}
                                <Token color='lava'>not assigned</Token> to{' '}
                                {appData.appLabel} {appData.label}
                              </Typography>
                              <Typography>
                                <Token>{userGroupData.oktaProfile.firstName}</Token> is a{' '}
                                <Token>{userGroupData.oktaProfile.title}</Token> for{' '}
                                <Token>{userGroupData.oktaProfile.department}</Token>,{' '}
                                <Token>{userGroupData.oktaProfile.organization}</Token>
                              </Typography>
                            </React.Fragment>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                    )}
                  </Box.Content>
                </Box>

                {userGroupData && (
                  <React.Fragment>
                    <Typography variant='header3'>
                      Groups
                      {false && (
                        <Dropdown
                          trigger={
                            <>
                              <Icon type='filter' />{' '}
                              <span style={{ marginLeft: 4 }}>Filter Groups</span>
                            </>
                          }
                        >
                          <FormLabel
                            label='Checkbox Option 1'
                            labelPosition='right'
                            fullWidth
                          >
                            <Checkbox
                              name='checkbox'
                              id='checkbox1'
                              onBlur={e => console.log('onBlur', e.currentTarget)}
                              onChange={e => console.log('onChange', e.currentTarget)}
                            />
                          </FormLabel>
                        </Dropdown>
                      )}
                    </Typography>
                  </React.Fragment>
                )}
                {userGroupData &&
                  userGroupData.current.map(group => {
                    return (
                      <UserAppGroup
                        key={group.id}
                        group={group}
                        preview={appData.oktaPreview}
                        userId={userGroupData.oktaUserId}
                        username={
                          userGroupData.oktaUsername || `UID ${userGroupData.oktaUserId}`
                        }
                        oktaAuth={oktaAuth}
                        refresh={getGroups}
                        showApps
                      />
                    );
                  })}
                {userGroupData &&
                  userGroupData.available.map(group => {
                    return (
                      <UserAppGroup
                        key={group.id}
                        group={group}
                        preview={appData.oktaPreview}
                        userId={userGroupData.oktaUserId}
                        username={
                          userGroupData.oktaUsername || `UID ${userGroupData.oktaUserId}`
                        }
                        oktaAuth={oktaAuth}
                        refresh={getGroups}
                        showApps
                      />
                    );
                  })}
              </React.Fragment>
            )}

            {activeTab === 'components' && (
              <Box margin={{ vertical: 'lg' }}>
                <Box.Title>Components</Box.Title>
                <Box.Content>
                  {modules && modules.length > 0 && (
                    <div className='st_row_application'>
                      {modules.map(module => {
                        return (
                          <ComponentBox
                            key={`component-box-${module.id}`}
                            componentKey={module.key}
                            {...module}
                            type='component'
                          />
                        );
                      })}
                    </div>
                  )}
                </Box.Content>
              </Box>
            )}
          </React.Fragment>
        )}
      </PageWrapper>
    </WrapperContext.Provider>
  );
};

export { useModuleWrapper };
export default Module;
