import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Loader } from '@screentone/core';
import Input from 'components/FormInput';
import { mergeReducer, formatPayload } from 'utils/fns';
import { notEmpty } from 'utils/validations';
import { useAuth } from '@screentone/addon-auth-wrapper';
import API from 'api/api';
import { useS3DeploymentWrapper } from 'pages/Application/Application.page';

const margin = { bottom: 'md' };
const boxContentPadding = { horizontal: 'mlg', vertical: 'md' };

function AppCreationForm({ closeModal }) {
  const { oktaAuth } = useAuth();
  const [rawData, updateRawData] = useReducer(mergeReducer, {});
  const { setApps } = useS3DeploymentWrapper();
  const [isLoading, setIsLoading] = useState(false);

  async function onCreate() {
    setIsLoading(true);
    const payload = formatPayload(rawData);
    const accessToken = await oktaAuth.getAccessToken();
    const am = await API({ accessToken });
    const result = await am.apps.create(payload);
    setApps(oldArray => [...oldArray, result.apps[0]]);
    setIsLoading(false);
    closeModal();
  }

  const isCreateButtonDisabled = Object.values(rawData).some(
    inputState => inputState.dirty === false || inputState.error !== null
  );

  const isDisabled = isCreateButtonDisabled || isLoading;

  return (
    <React.Fragment>
      <Typography size='xxl' componentEl='h1' margin={{ vertical: 'none', left: 'xs' }}>
        New Application
      </Typography>

      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <Input
            name='appName'
            title='Application name'
            margin={margin}
            data-testid='inp-name-app'
            validate={notEmpty}
            syncData={updateRawData}
          />
        </Box.Content>
      </Box>

      <div style={{ textAlign: 'right' }}>
        <Button
          primary
          onClick={onCreate}
          disabled={isDisabled}
          data-testid='btn-create-app'
        >
          {isLoading ? <Loader size='md' /> : null}
          Create
        </Button>
      </div>
    </React.Fragment>
  );
}

AppCreationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AppCreationForm;
