import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Input, Textarea, FormHelperText } from '@screentone/core';
import { useInput } from 'utils/hooks/useInput';
import { asyncIdentity } from 'utils/fns';

function FormInput({
  initialValue,
  validate,
  syncData,
  name,
  type,
  title,
  margin,
  disabled,
  placeholder,
  helpText,
}) {
  const { onBlur, onChange, ...inputState } = useInput({
    name,
    initialValue,
    validate,
    syncData,
  });

  const label = (
    <span data-djtool-id={`${name}-label`}>
      {title} {inputState.error ? inputState.error.message : ''}
    </span>
  );

  const componentProps = {
    onBlur,
    onChange,
    disabled,
    placeholder,
    value: inputState.value,
    error: Boolean(inputState.error),
  };

  return (
    <FormLabel label={label} fullWidth margin={margin}>
      {type === 'input' && (
        <Input aria-label={title} {...componentProps} data-djtool-id={`${name}-input`} />
      )}
      {type === 'textarea' && (
        <Textarea type='text' {...componentProps} data-djtool-id={`${name}-textarea`} />
      )}
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormLabel>
  );
}

FormInput.propTypes = {
  initialValue: PropTypes.string,
  validate: PropTypes.func,
  syncData: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['input', 'textarea']),
  title: PropTypes.string.isRequired,
  margin: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
};

FormInput.defaultProps = {
  type: 'input',
  initialValue: '',
  placeholder: '',
  helpText: null,
  disabled: false,
  validate: asyncIdentity,
};

export { FormInput };
export default React.memo(FormInput);
