import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/FormInput';
import { Button, Loader, Box } from '@screentone/core';
import { useProvider } from 'provider/Page/Page.provider';
import Select from 'react-select';
import API from 'api/api';
import { mergeReducer } from 'utils/fns';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { notEmpty } from 'utils/validations';
import { useDeploymentWrapper } from 'pages/Deployment/Deployment.page';

const margin = { bottom: 'md' };
const boxContentPadding = { horizontal: 'mlg', vertical: 'md' };

const DeployForm = ({ closeModal, build }) => {
  const { activeApp, activeStack, activeModule } = useProvider();
  const { oktaAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [rawData, updateRawData] = useReducer(mergeReducer, {});
  const { sha, description, SK: buildId } = build;
  const { setDeployment } = useDeploymentWrapper();

  const onCreateDeploy = async () => {
    setIsLoading(true);
    const descValue = rawData.description.value;

    const payload = {
      description: descValue,
      buildId: buildId.replace('build_', ''),
      componentId: activeModule.id, // Deploying for active component
    };

    const accessToken = await oktaAuth.getAccessToken();
    const am = await API({ accessToken });
    const result = await am.component.deploy.create(payload);
    const deployInfo = result[0];

    const triggerPayload = {
      type: 'deploy',
      environment: '',
      stack: activeStack.key,
      sha,
      appKey: activeApp.label,
      component: activeModule.name,
      deployId: deployInfo.SK.replace('deploy_', ''),
    };

    await am.deploy.jenkins.trigger(triggerPayload);

    setDeployment([deployInfo]);
    setIsLoading(false);
    closeModal();
  };

  const shortSha = sha.substr(0, 7);
  const shortDesc =
    description.length > 40 ? `${description.substr(0, 50)}...` : description;

  const selectedShaOption = { value: sha, label: `${shortSha} - ${shortDesc}` };

  const isCreateButtonDisabled = Object.values(rawData).some(
    inputState => inputState.dirty === false || inputState.error !== null
  );

  const isDisabled = isLoading || isCreateButtonDisabled;

  return (
    <div style={{ margin: '80px 0' }}>
      <h2 style={{ color: 'rgb(119, 119, 119)' }}>{activeApp.label}</h2>
      <h3 style={{ color: 'rgb(119, 119, 119)' }}>{activeModule.gitUrl}</h3>
      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <div style={{ margin: '15px 0' }}>
            <Select value={selectedShaOption} isDisabled />
          </div>
          <Input
            name='description'
            type='textarea'
            title='Description'
            margin={margin}
            data-testid='inp-desc-deploy'
            validate={notEmpty}
            syncData={updateRawData}
          />
        </Box.Content>
      </Box>
      <div style={{ textAlign: 'right' }}>
        <Button primary onClick={onCreateDeploy} disabled={isDisabled}>
          {isLoading ? <Loader size='md' /> : null}
          Deploy
        </Button>
      </div>
    </div>
  );
};

DeployForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  build: PropTypes.shape({
    sha: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    PK: PropTypes.string.isRequired,
    SK: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeployForm;
