import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useLocation } from 'react-router-dom';
import {
  Box,
  FormLabel,
  Button,
  List,
  Overlay,
  Textblock,
  Illustration,
  Loader,
  Tooltip,
  Toggle,
  ToggleItem,
  Textarea,
  Token,
} from '@screentone/core';

import API from 'api/api';
import TableBuilder from 'components/TableBuilder';
import './RequestAccess.css';

const buildLine = (title, content) => (
  <div className='access-details-line'>
    <b>{`${title}: `}</b>
    {content || <i>n/a</i>}
  </div>
);

const generateTemplates = appname => ({
  approve: `Yay! Your request for access to ${appname} has been approved.`,
  deny: `Sorry. Your request for access to ${appname} was not approved.`,
});

const RequestAccess = () => {
  const location = useLocation();
  const { authState } = useAuth();

  const [modal, setModal] = useState(null);
  const [request, setRequest] = useState(null);
  const [template, setTemplate] = useState('approve');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const appname = (request && `${request.appname} - ${request.stackname}`) || 'the app';
  const templates = generateTemplates(appname);

  const setEmailTemplate = value => {
    setTemplate(value);
    switch (value) {
      case 'approve':
        setEmail(templates.approve);
        break;
      case 'deny':
        setEmail(templates.deny);
        break;
      default:
        break;
    }
  };

  const changeEmailTemplate = event => {
    if (template !== 'custom') setTemplate('custom');
    setEmail(event.target.value);
  };

  const fetchReq = useCallback(async () => {
    if (!authState || !authState.accessToken) return null;
    const { accessToken } = authState;
    const params = new URLSearchParams(location.search);
    const reqId = params.get('id');
    if (reqId) {
      try {
        const am = await API({ accessToken });
        const data = await am.okta.group.to.user(reqId);
        const { approve } = generateTemplates(`${data.appname} - ${data.stackname}`);
        setRequest(data);
        setTemplate('approve');
        setEmail(approve);
      } catch (errorAccess) {
        setRequest(false);
        setError(errorAccess.message);
      }
    } else {
      setRequest(false);
    }
  }, [authState, location.search]);

  const addGroup = groupId => async () => {
    const { userId } = request;
    const { accessToken } = authState;
    try {
      const am = await API({ accessToken });
      await am.okta.add.user.to.group(groupId, userId);
      fetchReq();
      setModal(null);
    } catch (errorAddGroup) {
      console.log('Error adding user to group', errorAddGroup);
    }
  };

  const removeGroup = groupId => async () => {
    const { userId } = request;
    const { accessToken } = authState;
    try {
      const am = await API({ accessToken });
      await am.okta.remove.user.from.group(groupId, userId);
      fetchReq();
      setModal(null);
    } catch (errorRemoveGroup) {
      console.log('Error removing user to group', errorRemoveGroup);
    }
  };

  useEffect(() => {
    if (request === null && authState && authState.accessToken) {
      fetchReq();
    }
  }, [authState, fetchReq, request]);

  const showGroup = group => (
    <List.Item key={group.id} icon='info' onClick={() => setModal(group)}>
      {group.description ? (
        <Tooltip>
          <Tooltip.Content position='right'>{group.description}</Tooltip.Content>
          <Tooltip.Trigger>{group.name}</Tooltip.Trigger>
        </Tooltip>
      ) : (
        group.name
      )}
    </List.Item>
  );

  if (request === null) {
    return (
      <div className='request-empty-content'>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className='request-empty-content'>
        <Illustration className='request-empty-illustration' name='notes' />
        <h1>{error}</h1>
      </div>
    );
  }

  return (
    <div className='request-access-container'>
      {request && (
        <React.Fragment>
          <Box margin={{ bottom: 'mlg' }}>
            <Box.Title>Request Details</Box.Title>
            <Box.Content>
              {buildLine('Requester', request.name || request.userId || 'Someone')}
              {buildLine('Email', request.email)}
              {buildLine('Application', `${request.appname} - ${request.stackname}`)}
              {buildLine('Justification', request.justification)}
              {buildLine(
                'Properties',
                request.properties.length > 0 &&
                  request.properties.map(property => (
                    <Token key={property} margin={{ all: 'xs' }} color='blurple'>
                      {property}
                    </Token>
                  ))
              )}
              {buildLine(
                'Permissions',
                request.permissions.length > 0 &&
                  request.permissions.map(permission => (
                    <Token key={permission} margin={{ all: 'xs' }} color='blurple'>
                      {permission}
                    </Token>
                  ))
              )}
            </Box.Content>
          </Box>
          <Box margin={{ bottom: 'mlg' }}>
            <Box.Title>Access Groups</Box.Title>
            <Box.Content>
              <FormLabel label='Current Groups' fullWidth>
                <List className='access-group-existing'>
                  {request.groups && request.groups.current.length > 0 ? (
                    request.groups.current.map(group => showGroup(group))
                  ) : (
                    <List.Item className='access-no-group'>No Current Groups</List.Item>
                  )}
                </List>
              </FormLabel>
              <FormLabel label='Available Groups' fullWidth>
                <List className='access-group-existing'>
                  {request.groups && request.groups.available.length > 0 ? (
                    request.groups.available.map(group => showGroup(group))
                  ) : (
                    <List.Item className='access-no-group'>No Available Groups</List.Item>
                  )}
                </List>
              </FormLabel>
            </Box.Content>
          </Box>
        </React.Fragment>
      )}
      {/* TODO: implement email communication to requester direct from app */}
      <Box>
        <Box.Title>Communication</Box.Title>
        <Box.Content>
          <Toggle onChange={setEmailTemplate} value={template} margin={{ bottom: 'md' }}>
            <ToggleItem disabled value='approve'>
              Approve
            </ToggleItem>
            <ToggleItem disabled value='deny'>
              Deny
            </ToggleItem>
            <ToggleItem disabled value='custom'>
              Custom
            </ToggleItem>
          </Toggle>
          <Textarea
            disabled
            onChange={changeEmailTemplate}
            type='text'
            name='email'
            placeholder='Send to user'
            value={email}
          />
          <Button primary disabled>
            Send Email
          </Button>
        </Box.Content>
      </Box>
      {modal !== null && (
        <Overlay onDismiss={() => setModal(null)} status='open'>
          <Box margin={{ all: 'md' }}>
            <Box.Title>{modal.name || modal.id}</Box.Title>
            <Box.Content className='access-group-overlay'>
              <Textblock>
                <span>{modal.description}</span>
                <Box>
                  <Box.Content>
                    <TableBuilder
                      table={modal.profile}
                      propName='Setting'
                      propValue='Value'
                      celled
                    />
                  </Box.Content>
                </Box>
              </Textblock>
              {modal.assigned ? (
                <Button
                  margin={{ top: 'md' }}
                  onClick={removeGroup(modal.id)}
                  tertiary
                  icon='trash'
                  color='lava'
                >{`Remove from ${request.name || 'user'}`}</Button>
              ) : (
                <Button
                  margin={{ top: 'md' }}
                  onClick={addGroup(modal.id)}
                  tertiary
                  icon='plus-circle'
                  color='blurple'
                >{`Add to ${request.name || 'user'}`}</Button>
              )}
            </Box.Content>
          </Box>
        </Overlay>
      )}
    </div>
  );
};

export default RequestAccess;
