import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Loader } from '@screentone/core';
import Input from 'components/FormInput';
import { mergeReducer, formatPayload, asyncPipe } from 'utils/fns';
import { notEmpty, isGitUrl } from 'utils/validations';
import { useAuth } from '@screentone/addon-auth-wrapper';
import API from 'api/api';

import { useModuleWrapper } from 'pages/Module/Module.page';

const margin = { bottom: 'md' };
const boxContentPadding = { horizontal: 'mlg', vertical: 'md' };

function ComponentCreationForm({ closeModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const { oktaAuth } = useAuth();
  const { appKey, stackKey } = useParams();
  const [rawData, updateRawData] = useReducer(mergeReducer, {});
  const { setModules } = useModuleWrapper();

  const isValidUrl = asyncPipe(notEmpty, isGitUrl);

  async function onCreate() {
    setIsLoading(true);
    const formattedData = formatPayload(rawData);
    formattedData.moduleType = 's3';
    const accessToken = await oktaAuth.getAccessToken();

    const payload = { ...formattedData };

    const am = await API({ accessToken });
    const response = await am.component.create(appKey, stackKey, payload);
    const component = response.component[0];

    const configPayload = {
      description: payload.description,
      componentId: component.id,
      gitUrl: payload.gitUrl,
    };

    await am.component.create.config(configPayload);
    setModules(oldArray => [...oldArray, component]);
    setIsLoading(false);
    closeModal();
  }

  const isCreateButtonDisabled = Object.values(rawData).some(
    inputState => inputState.dirty === false || inputState.error !== null
  );

  const isDisabled = isCreateButtonDisabled || isLoading;

  return (
    <React.Fragment>
      <Typography size='xxl' componentEl='h1' margin={{ vertical: 'none', left: 'xs' }}>
        New component
      </Typography>

      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <Input
            name='name'
            title='Name'
            margin={margin}
            data-testid='inp-desc-stack'
            validate={notEmpty}
            syncData={updateRawData}
          />
          <Input
            name='description'
            title='Description'
            margin={margin}
            data-testid='inp-desc-stack'
            validate={notEmpty}
            syncData={updateRawData}
          />
          <Input
            name='gitUrl'
            title='URL Repository'
            margin={margin}
            data-testid='inp-url-repo'
            validate={isValidUrl}
            syncData={updateRawData}
          />
        </Box.Content>
      </Box>

      <div style={{ textAlign: 'right' }}>
        <Button
          primary
          onClick={onCreate}
          disabled={isDisabled}
          data-testid='btn-create-app'
        >
          {isLoading ? <Loader size='md' /> : null}
          Create
        </Button>
      </div>
    </React.Fragment>
  );
}

ComponentCreationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ComponentCreationForm;
