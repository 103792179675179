export default {
  'app.get.all': {
    method: 'GET',
    path: 'api/v0/app',
    authenticated: true,
  },
  'apps.active': {
    method: 'GET',
    path: 'api/v0/app',
    authenticated: true,
  },
  'apps.create': {
    method: 'POST',
    path: 'api/v0/app',
    authenticated: true,
  },
  'apps.delete': {
    method: 'DELETE',
    path: 'api/v0/app/:appKey',
    authenticated: true,
  },
  'stack.create': {
    method: 'POST',
    path: 'api/v0/app/:appKey/stack',
    authenticated: true,
  },
  'app.stacks': {
    method: 'GET',
    path: 'api/v0/app/:appKey/stack',
    authenticated: true,
  },
  listUserAppAssignments: {
    method: 'GET',
    path: 'api/v0/app/:appKey/stack/:stackKey/assignments/:userKey',
    authenticated: true,
  },
  appByKey: {
    method: 'GET',
    path: 'api/v0/apps/:appKey/app',
    authenticated: true,
  },
  stackByKey: {
    method: 'GET',
    path: 'api/v0/app/:appKey/stack/:stackKey',
    authenticated: true,
  },
  componentByKey: {
    method: 'GET',
    path: 'api/v0/apps/:appKey/:stackKey/component/:componentKey',
    authenticated: true,
  },
  'component.config': {
    method: 'GET',
    path: 'api/v0/s3/component/:componentId',
    authenticated: true,
  },
  'component.create.config': {
    method: 'POST',
    path: 'api/v0/s3/component',
    authenticated: true,
  },
  'stack.components': {
    method: 'GET',
    path: 'api/v0/app/:appKey/:stackKey/component',
    authenticated: true,
  },
  'component.create': {
    method: 'POST',
    path: 'api/v0/apps/:appKey/:stackKey/component',
    authenticated: true,
  },
  'stack.builds': {
    method: 'GET',
    path: 'api/v0/s3/build',
    authenticated: true,
  },
  'github.branches': {
    method: 'GET',
    path: 'api/v0/apps/getBranches',
    authenticated: true,
  },
  'github.commits': {
    method: 'GET',
    path: 'api/v0/apps/getCommits',
    authenticated: true,
  },
  'build.create': {
    method: 'POST',
    path: 'api/v0/s3/build',
    authenticated: true,
  },
  'build.jenkins.trigger': {
    method: 'POST',
    path: 'api/v0/s3/deployment',
    authenticated: true,
  },
  'component.deploy': {
    method: 'GET',
    path: 'api/v0/s3/deploy',
    authenticated: true,
  },
  'component.deploy.create': {
    method: 'POST',
    path: 'api/v0/s3/deploy',
    authenticated: true,
  },
  'deploy.jenkins.trigger': {
    method: 'POST',
    path: 'api/v0/s3/deployment',
    authenticated: true,
  },
  'request.access': {
    method: 'GET',
    path: 'api/v0/access/:id',
    authenticated: true,
  },
  'okta.group.to.user': {
    method: 'GET',
    path: 'api/v0/groups/:groupId/users/:userId',
    authenticated: true,
  },
  'okta.add.user.to.group': {
    method: 'PUT',
    path: 'api/v0/groups/:groupId/users/:userId',
    authenticated: true,
    options: {
      response: 'text',
    },
  },
  'okta.remove.user.from.group': {
    method: 'DELETE',
    path: 'api/v0/groups/:groupId/users/:userId',
    authenticated: true,
    options: {
      response: 'text',
    },
  },
  'build.byId': {
    method: 'GET',
    path: 'api/v0/s3/build',
    authenticated: true,
  },
  'deploy.byId': {
    method: 'GET',
    path: 'api/v0/s3/deploy',
    authenticated: true,
  },
};
