class ValidationError extends Error {
  constructor(message, metadata = {}) {
    super(message);

    Object.keys(metadata).forEach(key => {
      this[key] = metadata[key];
    });

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
  }
}

async function notEmpty(value) {
  if (!value || value.length < 1) {
    await Promise.reject(new ValidationError(' - Required'));
  }
  return value;
}

async function isGitUrl(str) {
  // eslint-disable-next-line no-useless-escape
  const regex = /(?:git|ssh|https?|git@[-\w.]+):(\/\/)?(.*?)(\.git)(\/?|\#[-\d\w._]+?)$/;
  if (!regex.test(str)) {
    await Promise.reject(new ValidationError(' - Invalid Url'));
  }
  return str;
}

export { notEmpty, isGitUrl, ValidationError };
