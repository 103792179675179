import React, { useEffect, useState } from 'react';
import BuildCreation from 'components/BuildCreation';
import { Modal, useModalState, useModalPortal } from '@screentone/core';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import Loading from 'components/Loading';
import DeployForm from 'components/DeployForm';
import BuildRow from 'components/Tabs/S3Build/BuildRow.component';
import { useProvider } from 'provider/Page/Page.provider';
import './S3Build.styles.css';

const S3BuildTab = () => {
  const { oktaAuth } = useAuth();
  const [builds, setBuilds] = useState([]);
  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();
  const [buildObject, setBuildObject] = useState({});
  const { activeApp } = useProvider();

  useEffect(() => {
    const getStackByApplication = async () => {
      if (activeApp && activeApp.id) {
        const accessToken = await oktaAuth.getAccessToken();
        const am = await API({ accessToken });
        const result = await am.stack.builds({ appId: activeApp.id });
        setBuilds(result);
      }
    };

    try {
      getStackByApplication();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error getting stacks: ', error);
    }
  }, [activeApp, oktaAuth]);

  const onClickDeploy = ({ build }) => {
    setBuildObject(build);
    openModal(true);
  };

  return (
    <React.Fragment>
      <BuildCreation setBuilds={setBuilds} />
      <table className='table'>
        <tbody>
          {builds.length === 0 ? (
            <tr>
              <th colSpan='4'>No Builds Found</th>
            </tr>
          ) : (
            builds.map(build => {
              const onClick = () => onClickDeploy({ build });
              return <BuildRow key={build.sha} {...build} onClickDeploy={onClick} />;
            })
          )}
        </tbody>
      </table>
      {open && (
        <Modal
          onDismiss={closeModal}
          renderNode={renderNode}
          data-testid='mdl-create-app'
        >
          <React.Suspense fallback={<Loading />}>
            <DeployForm closeModal={closeModal} build={buildObject} />
          </React.Suspense>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default S3BuildTab;
