import React from 'react';
import PropTypes from 'prop-types';
import { FabButton, Modal, useModalState, useModalPortal } from '@screentone/core';

import Loading from 'components/Loading';
import DeployCreationForm from 'components/DeployForm/DeployFormWithBuildId.component';

function DeployCreation({ setDeploys }) {
  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();

  return (
    <React.Fragment>
      <FabButton
        icon='plus'
        onClick={() => openModal(true)}
        data-testid='fab-button-build'
      />
      {open && (
        <Modal
          onDismiss={closeModal}
          renderNode={renderNode}
          data-testid='mdl-create-app'
        >
          <React.Suspense fallback={<Loading />}>
            <DeployCreationForm closeModal={closeModal} setDeploys={setDeploys} />
          </React.Suspense>
        </Modal>
      )}
    </React.Fragment>
  );
}

DeployCreation.propTypes = {
  setDeploys: PropTypes.func.isRequired,
};

export default DeployCreation;
