import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/FormInput';
import { Button, Loader, Box } from '@screentone/core';
import { useProvider } from 'provider/Page/Page.provider';
import Select from 'react-select';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { mergeReducer } from 'utils/fns';
import { notEmpty } from 'utils/validations';

const margin = { bottom: 'md' };
const boxContentPadding = { horizontal: 'mlg', vertical: 'md' };

const DropdownBuilds = ({ activeBuild, builds, setActive }) => {
  const options = builds.map(build => {
    const { sha, description } = build;
    const shortSha = sha.substr(0, 7);
    const shortDesc =
      description.length > 40 ? `${description.substr(0, 50)}...` : description;
    return { value: build.sha, label: `${shortSha} - ${shortDesc}` };
  });

  const selectedOption = activeBuild ? { value: activeBuild, label: activeBuild } : null;

  const onchange = build => {
    setActive(build.value);
  };

  return <Select value={selectedOption} onChange={onchange} options={options} />;
};

DropdownBuilds.propTypes = {
  builds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.func.isRequired,
  activeBuild: PropTypes.string.isRequired,
};

const DeployFormWithBuild = ({ closeModal, setDeploys }) => {
  const { activeApp, activeModule, activeStack } = useProvider();
  const { oktaAuth } = useAuth();
  const [builds, setBuilds] = useState([]);
  const [activeBuild, setActiveBuild] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rawData, updateRawData] = useReducer(mergeReducer, {});

  useEffect(() => {
    const getStackByApplication = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const result = await am.stack.builds({ appId: activeApp.id });
      setBuilds(result);
    };

    try {
      getStackByApplication();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error getting stacks: ', error);
    }
  }, [activeApp.id, oktaAuth]);

  const onCreateDeploy = async () => {
    setIsLoading(true);
    const buildIdSelected = builds.find(i => i.sha === activeBuild);
    const componentId = activeModule.id;
    const descValue = rawData.description.value;
    const payload = {
      description: descValue,
      buildId: buildIdSelected.SK.replace('build_', ''),
      componentId,
    };

    const accessToken = await oktaAuth.getAccessToken();
    const am = await API({ accessToken });
    const result = await am.component.deploy.create(payload);
    const deployInfo = result[0];

    const triggerPayload = {
      type: 'deploy',
      environment: 'development', // Review and delete the environment
      stack: activeStack.key,
      sha: buildIdSelected.sha,
      appKey: activeApp.label,
      component: activeModule.name,
      deployId: deployInfo.SK.replace('deploy_', ''),
    };
    await am.deploy.jenkins.trigger(triggerPayload);

    const newDeploy = {
      buildId: deployInfo.buildId,
      id: deployInfo.id,
      status: deployInfo.status,
      createdAt: deployInfo.createdAt,
      description: deployInfo.description,
      userEmail: deployInfo.userEmail,
    };

    setDeploys(oldArray => [...oldArray, newDeploy]);
    setIsLoading(false);
    closeModal();
  };

  const isCreateButtonDisabled = Object.values(rawData).some(
    inputState => inputState.dirty === false || inputState.error !== null
  );

  const isDisabled = activeBuild === null || isLoading || isCreateButtonDisabled;

  return (
    <div style={{ margin: '80px 0' }}>
      <h2 style={{ color: 'rgb(119, 119, 119)' }}>{activeApp.label}</h2>
      <h3 style={{ color: 'rgb(119, 119, 119)' }}>{activeModule.gitUrl}</h3>
      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <div style={{ margin: '15px 0' }}>
            <DropdownBuilds
              builds={builds}
              activeBuild={activeBuild}
              setActive={setActiveBuild}
            />
          </div>
          <Input
            name='description'
            type='textarea'
            title='Description'
            margin={margin}
            data-testid='inp-desc-deploy'
            validate={notEmpty}
            syncData={updateRawData}
          />
        </Box.Content>
      </Box>
      <div style={{ textAlign: 'right' }}>
        <Button primary onClick={onCreateDeploy} disabled={isDisabled}>
          {isLoading ? <Loader size='md' /> : null}
          Deploy
        </Button>
      </div>
    </div>
  );
};

DeployFormWithBuild.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setDeploys: PropTypes.func.isRequired,
};

export default DeployFormWithBuild;
