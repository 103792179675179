/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Token, Button, HeaderBar } from '@screentone/core';
import API from 'api/api';

import './UserAppGroup.styles.css';

function UserAppGroup({ group, preview, userId, username, oktaAuth, refresh }) {
  async function assignUserToGroup() {
    try {
      const accessToken = await oktaAuth.getAccessToken();
      const am = API({ accessToken }).use((_n, _d, fetchInit) => {
        fetchInit.headers.set('preview', preview);
        return fetchInit;
      });
      await am.okta.add.user.to.group(group.id, userId, {});
      refresh();
    } catch (err) {
      console.error(err);
    }
  }

  async function removeUserFromGroup() {
    try {
      const accessToken = await oktaAuth.getAccessToken();
      const am = API({ accessToken }).use((_n, _d, fetchInit) => {
        fetchInit.headers.set('preview', preview);
        return fetchInit;
      });
      await am.okta.remove.user.from.group(group.id, userId, {});
      refresh();
    } catch (err) {
      console.error(err);
    }
  }

  const { profile, assigned, type, apps } = group;
  const { app_owner, app_admin, app_properties } = profile;

  const appScope = profile.appScope || profile.app_scope || profile.app_roles;

  return (
    <Box margin={{ vertical: 'md' }}>
      <Box.Title>
        {assigned || type ? (
          <div className='st_assigned_group'>
            <div>{group.name}</div>
            <div>
              {assigned && (
                <Token icon='check-circle' color='emerald' margin={{ horizontal: 'xs' }}>
                  Member
                </Token>
              )}
              {type === 'APP_GROUP' && (
                <Token icon='lock' color='lava' margin={{ horizontal: 'xs' }}>
                  Active Directory
                </Token>
              )}
              {type === 'BIRTHRIGHT' && (
                <Token icon='lock' color='lava' margin={{ horizontal: 'xs' }}>
                  Birthright
                </Token>
              )}
            </div>
          </div>
        ) : (
          group.name
        )}
      </Box.Title>
      <Box.Content>
        <div>
          {app_owner && (
            <Token icon='key' color='lava' margin={{ horizontal: 'xs' }}>
              Owner
            </Token>
          )}
          {app_admin && (
            <Token icon='gear' color='emerald' margin={{ horizontal: 'xs' }}>
              Admin
            </Token>
          )}
        </div>
        {group.description && <p>{group.description}</p>}

        <div className='productListWrapper'>
          {(app_properties || []).sort().map(item => (
            <HeaderBar.Product
              className='productListItem'
              key={item}
              title={item}
              type={item}
            />
          ))}
        </div>

        <div>
          {(appScope || []).sort().map(scope => (
            <Token
              key={`${group.name}_${scope}`}
              color='gray'
              margin={{ horizontal: 'xs' }}
            >
              {scope}
            </Token>
          ))}
        </div>
        {apps && (
          <div>
            {apps.length > 0 && `This group is also assigned to: `}
            {apps
              .sort((a, b) => {
                if (a.label < b.label) return -1;
                return 1;
              })
              .map(app => (
                <Token
                  key={`${group.id}_${app.id}`}
                  color='ice'
                  margin={{ horizontal: 'xs' }}
                >
                  {app.label}
                </Token>
              ))}
          </div>
        )}
        {type === 'OKTA_GROUP' && (
          <div className='st_assignment_button_container'>
            <Button
              className={(assigned && 'st_destructive_button') || null}
              onClick={assigned ? removeUserFromGroup : assignUserToGroup}
              margin={{ top: 'lg' }}
              secondary
            >
              {`${assigned ? 'Remove' : 'Assign'} ${username}`}
            </Button>
          </div>
        )}
      </Box.Content>
    </Box>
  );
}

UserAppGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    assigned: PropTypes.bool,
    type: PropTypes.string,
    profile: PropTypes.shape({
      app_owner: PropTypes.bool,
      app_admin: PropTypes.bool,
      appScope: PropTypes.arrayOf(PropTypes.string),
      app_properties: PropTypes.arrayOf(PropTypes.string),
      app_scope: PropTypes.arrayOf(PropTypes.string),
      app_roles: PropTypes.arrayOf(PropTypes.string),
    }),
    apps: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  preview: PropTypes.bool,
  userId: PropTypes.string,
  username: PropTypes.string,
  oktaAuth: PropTypes.shape({
    getAccessToken: PropTypes.func.isRequired,
  }).isRequired,
  refresh: PropTypes.func.isRequired,
  showApps: PropTypes.bool,
};

UserAppGroup.defaultProps = {
  group: {
    id: '',
    name: '',
    description: '',
    assigned: null,
    type: null,
    profile: {
      app_owner: null,
      app_admin: null,
      appScope: [],
      app_properties: [],
    },
    apps: [],
  },
  preview: null,
  userId: null,
  username: null,
  showApps: false,
};

export default UserAppGroup;
