import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Loader } from '@screentone/core';
import API from 'api/api';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useProvider } from 'provider/Page/Page.provider';
import Select from 'react-select';

const BranchDrowpdown = ({ activeBranch, branches, setActive, setActiveCommit }) => {
  const options = branches.map(branch => {
    return { value: branch, label: branch };
  });

  const selectedOption = { value: activeBranch, label: activeBranch };
  const onchange = branch => {
    setActive(branch.value);
    setActiveCommit(null);
  };

  return <Select value={selectedOption} onChange={onchange} options={options} />;
};

BranchDrowpdown.propTypes = {
  activeBranch: PropTypes.string.isRequired,
  branches: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.func.isRequired,
  setActiveCommit: PropTypes.func.isRequired,
};

const CommitsDropdown = ({ activeCommit, commits, setActive }) => {
  const options = commits.map(cm => {
    const label = `${cm.sha.substring(0, 7)} - ${cm.commit.message.substring(0, 50)}`;
    return { value: cm.sha, label };
  });

  const selectedOption = activeCommit
    ? { value: activeCommit, label: activeCommit }
    : null;

  const onchange = branch => {
    setActive(branch.value);
  };

  return <Select value={selectedOption} onChange={onchange} options={options} />;
};

CommitsDropdown.propTypes = {
  activeCommit: PropTypes.string.isRequired,
  commits: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.func.isRequired,
};

function AppCreationForm({ closeModal, setBuilds }) {
  const [branches, setBranches] = useState([]);
  const [commits, setCommits] = useState([]);
  const [activeBranch, setActiveBranch] = useState('master');
  const [activeCommit, setActiveCommit] = useState('');
  const { oktaAuth } = useAuth();
  const { activeApp, activeModule, activeStack } = useProvider();
  const [isLoading, setIsLoading] = useState(false);

  async function onCreateBuild() {
    setIsLoading(true);
    const selectedCommit = commits.find(cm => cm.sha === activeCommit);
    const { message } = selectedCommit.commit;
    const payload = {
      componentId: activeModule.id,
      sha: activeCommit,
      applicationId: activeApp.id,
      message,
    };

    const accessToken = await oktaAuth.getAccessToken();
    const am = await API({ accessToken });
    const build = await am.build.create(payload);
    const buildData = build[0];

    const triggerPayload = {
      gitUrl: activeModule.gitUrl,
      stack: activeStack.key,
      branch: '/',
      sha: activeCommit,
      appKey: activeApp.label,
      component: activeModule.name,
      type: 'build',
      buildId: buildData.SK.replace('build_', ''),
    };

    await am.build.jenkins.trigger(triggerPayload);

    setBuilds(oldArray => [...oldArray, buildData]);
    setIsLoading(false);
    closeModal();
  }

  useEffect(() => {
    const getApplications = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const result = await am.github.branches({ url: activeModule.gitUrl });
      setBranches(result.branches);
    };

    try {
      getApplications();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    }
  }, [activeModule.gitUrl, oktaAuth]);

  useEffect(() => {
    const getApplications = async () => {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const result = await am.github.commits({
        url: activeModule.gitUrl,
        branch: activeBranch,
      });
      setCommits(result.commits);
    };

    try {
      getApplications();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    }
  }, [oktaAuth, activeBranch, activeModule.gitUrl]);

  const isCreateButtonDisabled =
    activeBranch === null || activeCommit === null || isLoading;

  return (
    <React.Fragment>
      <Typography size='xxl' componentEl='h1' margin={{ vertical: 'none', left: 'xs' }}>
        New Build
      </Typography>

      <div style={{ margin: '15px 0' }}>
        <BranchDrowpdown
          activeBranch={activeBranch}
          branches={branches}
          setActive={setActiveBranch}
          setActiveCommit={setActiveCommit}
        />
      </div>
      <div style={{ margin: '15px 0' }}>
        <CommitsDropdown
          activeCommit={activeCommit}
          commits={commits}
          setActive={setActiveCommit}
        />
      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          primary
          onClick={onCreateBuild}
          disabled={isCreateButtonDisabled}
          data-testid='btn-create-app'
        >
          {isLoading ? <Loader size='md' /> : null}
          Create
        </Button>
      </div>
    </React.Fragment>
  );
}

AppCreationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setBuilds: PropTypes.func.isRequired,
};

export default AppCreationForm;
