import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Typography,
  Loader,
  FormLabel,
  FormHelperText,
  Select,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import get from 'lodash/get';
import clone from 'lodash/clone';
import Input from 'components/FormInput';
import { mergeStackReducer, formatPayload } from 'utils/fns';
import { notEmpty, ValidationError } from 'utils/validations';
import API from 'api/api';
import { useStackWrapper } from 'pages/Stack/Stack.page';

const margin = { bottom: 'md' };
const boxContentPadding = { horizontal: 'mlg', vertical: 'md' };
const propertyLabels = {
  wsj: 'The Wall Street Journal',
  barrons: "Barron's",
  marketwatch: 'MarketWatch',
  mansionglobal: 'Mansion Global',
  fnlondon: 'FN London',
  pen: 'Private Equity News',
};
function StackCreationForm({ closeModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const { oktaAuth } = useAuth();
  const { appKey } = useParams();
  const [rawData, updateRawData] = useReducer(mergeStackReducer, {});
  const { setStacks } = useStackWrapper();

  async function onCreate() {
    setIsLoading(true);
    const payload = formatPayload(rawData);
    const accessToken = await oktaAuth.getAccessToken();
    const api = await API({ accessToken });

    const myHeader = (name, definition, fetchInit, mwParam) => {
      console.log('name: ', name);
      console.log('definition: ', definition);
      console.log('fetchInit: ', fetchInit);
      console.log('mwParam: ', mwParam);
      console.log('payload: ', payload);
      console.log('payload.preview: ', payload.preview);
      if (payload.preview) {
        fetchInit.headers.set('x-preview', payload.preview);
      }
      return fetchInit;
    };

    api.use(myHeader);

    const result = await api.stack.create(appKey, payload);
    setStacks(result.stacks);
    setIsLoading(false);
    closeModal();
  }
  async function checkDomain(value) {
    if (value && value.length > 0) {
      const pattern = new RegExp(
        /(https?):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        'i'
      );
      const isValidUrl = !!pattern.test(value);
      const oktaDomain = get(rawData, 'oktaDomain.value');

      if (!isValidUrl) {
        await Promise.reject(new ValidationError(' - Invalid URL'));
      } else if (oktaDomain) {
        const url = new URL(value);
        if (oktaDomain.split('.')[1].toLowerCase() === 'okta') {
          if (url.protocol !== 'https:') {
            await Promise.reject(new ValidationError(' - Invalid URL. Must be https:'));
          } else if (url.hostname === 'localhost') {
            await Promise.reject(
              new ValidationError(' - Invalid URL. Must not be localhost')
            );
          }
        }
      }
    }
    return value;
  }

  async function oktaDomainSelect({ currentTarget }) {
    const data = {
      [currentTarget.name]: {
        error: null,
        dirty: false,
        validating: null,
        value: '',
      },
    };
    if (currentTarget.value.length > 0) {
      data[currentTarget.name].dirty = true;
      data[currentTarget.name].value = currentTarget.value;
      if (rawData.clientUri) {
        data.clientUri = clone(rawData.clientUri);
        try {
          data.clientUri.error = null;
          await checkDomain(rawData.clientUri.value);
        } catch (validationError) {
          data.clientUri.error = validationError;
        }
      }
    }
    updateRawData(data);
    // const isValid = checkDomain(currentTarget.value);
    // console.log('isValid: ', isValid);
  }

  const isCreateButtonDisabled = Object.values(rawData).some(inputState => {
    return inputState.dirty === false || inputState.error !== null;
  });

  const isDisabled = isCreateButtonDisabled || isLoading;

  return (
    <React.Fragment>
      <Typography size='xxl' componentEl='h1' margin={{ vertical: 'none', left: 'xs' }}>
        New Stack
      </Typography>

      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <Input
            name='stackName'
            title='Stack name'
            margin={margin}
            data-testid='inp-name-stack'
            validate={notEmpty}
            syncData={updateRawData}
          />
          {rawData.stackKey && <FormHelperText>{rawData.stackKey.value}</FormHelperText>}
        </Box.Content>
      </Box>
      <Box margin={margin}>
        <Box.Content padding={boxContentPadding}>
          <FormLabel label='Okta Domain' labelPosition='top' fullWidth margin={margin}>
            <Select
              name='oktaDomain'
              defaultValue=''
              // validate={notEmpty}
              // syncData={updateRawData}
              onBlur={oktaDomainSelect}
              onChange={oktaDomainSelect}
              value={get(rawData, 'oktaDomain.value')}
              error={Boolean(get(rawData, 'oktaDomain.error', false))}
            >
              <option value=''>Please Select</option>
              <option value='newscorp.oktapreview.com'>newscorp.oktapreview.com</option>
              <option value='newscorp.okta.com'>newscorp.okta.com</option>
            </Select>
          </FormLabel>
          <Input
            name='clientUri'
            title='Application URL'
            margin={margin}
            data-testid='inp-domain'
            placeholder='Enter the domain of your app, e.g. https://apps.djnews.tools'
            validate={checkDomain}
            syncData={updateRawData}
            helpText='http://localhost can only be used with Okta Preview, none Okta Preview URLs need to start with https://'
          />
          <FormLabel label='Login redirect URI' labelPosition='top' fullWidth>
            <FormHelperText>
              {rawData.redirectUris && rawData.redirectUris.value
                ? rawData.redirectUris.value
                : ''}
            </FormHelperText>
          </FormLabel>
          <FormLabel
            label='Logout redirect URI'
            labelPosition='top'
            fullWidth
            margin={margin}
          >
            <FormHelperText>
              {rawData.logoutUris && rawData.logoutUris.value
                ? rawData.logoutUris.value
                : ''}
            </FormHelperText>
          </FormLabel>

          <FormLabel label='Properties' labelPosition='top' fullWidth margin={margin}>
            {Object.keys(propertyLabels).map(property => {
              return (
                <FormLabel
                  key={property}
                  label={propertyLabels[property]}
                  labelPosition='right'
                  fullWidth
                >
                  <Checkbox
                    name={property}
                    id={property}
                    onChange={() => console.log('onChange', property)}
                    onBlur={() => console.log('onBlur', property)}
                  />
                </FormLabel>
              );
            })}
          </FormLabel>
        </Box.Content>
      </Box>

      <div style={{ textAlign: 'right' }}>
        <Button
          primary
          onClick={onCreate}
          disabled={isDisabled}
          data-testid='btn-create-app'
        >
          {isLoading ? <Loader size='md' /> : null}
          Create
        </Button>
      </div>
    </React.Fragment>
  );
}

StackCreationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default StackCreationForm;
