import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@screentone/core';

import './TableBuilder.css';

const TableBuilder = ({ table, header, propName, propValue, celled }) => {
  if (!table) return null;

  const tableClasses = ['tb-content'];
  if (celled) {
    tableClasses.push('tb-celled');
  }

  return (
    <div className={tableClasses.join(' ')}>
      {header && <h1>{header}</h1>}
      <table className='table'>
        <thead>
          <tr>
            <th>{propName}</th>
            <th>{propValue}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(table).map(claimEntry => {
            const claimName = claimEntry[0];
            const claimValue =
              typeof claimEntry[1] === 'object'
                ? JSON.stringify(claimEntry[1])
                : claimEntry[1];
            const claimId = `claim-${claimName}`;
            return (
              <tr key={claimName}>
                <td>{claimName}</td>
                <td id={claimId}>
                  {typeof claimValue === 'boolean' ? (
                    <Icon
                      color={claimValue ? 'emerald' : 'lava'}
                      type={claimValue ? 'check' : 'cross'}
                    />
                  ) : (
                    claimValue
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

TableBuilder.propTypes = {
  table: PropTypes.shape({}).isRequired,
  header: PropTypes.string,
  propName: PropTypes.string,
  propValue: PropTypes.string,
  celled: PropTypes.bool,
};

TableBuilder.defaultProps = {
  header: null,
  propName: 'Key',
  propValue: 'Value',
  celled: false,
};

export default TableBuilder;
