import React from 'react';
import { Redirect } from 'react-router-dom';
import { IconSymbol } from '@screentone/core';
import { AuthRouter, ProtectedRoute } from '@screentone/addon-auth-wrapper';

import ApplicationPage from 'pages/Application';
import DeploymentPage from 'pages/Deployment';
import ModulesPage from 'pages/Module';
import RequestAccess from 'pages/RequestAccess';
import StackPage from 'pages/Stack';
import Swagger from 'pages/Swagger';
import PageProvider from 'provider/Page';
import { getConfig } from 'utils/fns';

function App() {
  const config = getConfig();

  const header = {
    userLinks: [
      {
        href: '/docs/api',
        text: `Swagger Docs`,
      },
    ],
  };

  return (
    <React.Fragment>
      <AuthRouter
        app={config.app}
        authConfig={config.oidc}
        provider={PageProvider}
        headerBar={header}
        homePage={() => <Redirect push to='/apps' />}
      >
        <ProtectedRoute exact path='/apps' component={ApplicationPage} />
        <ProtectedRoute exact path='/app/:appKey/stacks' component={StackPage} />
        <ProtectedRoute
          exact
          path='/app/:appKey/stacks/:stackKey/components'
          component={ModulesPage}
        />
        <ProtectedRoute
          exact
          path='/app/:appKey/stacks/:stackKey/components/:componentKey/builds'
          component={DeploymentPage}
        />
        <ProtectedRoute exact path='/access' component={RequestAccess} />
        <ProtectedRoute exact path='/docs/api' component={Swagger} />
      </AuthRouter>
      <IconSymbol />
    </React.Fragment>
  );
}

export default App;
