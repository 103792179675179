import { useEffect, useReducer } from 'react';
import { mergeReducer } from 'utils/fns';

function useInput({
  name = '',
  initialValue = '',
  validate = () => {},
  syncData = () => {},
}) {
  const [inputState, setInputState] = useReducer(mergeReducer, {
    error: null,
    dirty: false,
    validating: null,
    value: initialValue,
  });

  useEffect(() => {
    syncData({ [name]: inputState });
  }, [syncData, name, inputState]);

  function onChange(event) {
    event.persist();
    const { value } = event.target;
    setInputState({ value });
  }

  async function onBlur() {
    setInputState({ validating: true, error: null, dirty: true });
    try {
      const newValue = await validate(inputState.value);
      setInputState({ validating: false, error: null, value: newValue });
    } catch (validationError) {
      setInputState({ validating: false, error: validationError });
    }
  }

  return { onChange, onBlur, ...inputState };
}

export { useInput };
