import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'utils/hooks/useInterval';
import { Button, Loader } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import API from 'api/api';

const BuildRow = ({
  sha,
  status,
  description,
  userEmail,
  createdAt,
  onClickDeploy,
  SK,
}) => {
  const { oktaAuth } = useAuth();
  const [delay, setDelay] = useState(5000);
  const [statusBuild, setStatusBuild] = useState(status);

  const customClass = statusBuild.toLowerCase();
  const desc = description.length > 40 ? `${description.substr(0, 50)}...` : description;
  const buildId = SK.replace('build_', '');
  const callback = async () => {
    try {
      const accessToken = await oktaAuth.getAccessToken();
      const am = await API({ accessToken });
      const buildData = await am.build.byId({ buildId });
      const data = buildData[0];
      if (data.status === 'COMPLETE') {
        setStatusBuild('COMPLETE');
        setDelay(null);
      }
    } catch (error) {
      setStatusBuild('FAIL');
      setDelay(null);
    }
  };

  useInterval(callback, delay);

  return (
    <React.Fragment>
      <tr key={sha.substr(0, 7)}>
        <th className={customClass}>{statusBuild}</th>
        <th className='build-sha'>{sha.substr(0, 7)}</th>
        <th>{userEmail}</th>
        <th>{desc}</th>
        <th>{new Date(createdAt).toLocaleString()}</th>
        <th>
          {statusBuild !== 'COMPLETE' ? (
            <Loader size='md' />
          ) : (
            <Button active tertiary onClick={onClickDeploy}>
              Deploy
            </Button>
          )}
        </th>
      </tr>
    </React.Fragment>
  );
};

BuildRow.propTypes = {
  onClickDeploy: PropTypes.func.isRequired,
  sha: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  SK: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
};

export default BuildRow;
